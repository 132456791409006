import React from "react";
import styled from "styled-components";
import { Block, Container } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import weTechFoodLogo from "../assets/logo_i.png";
import WeTechFoodLogo from "../assets/logo.svg";

import Instagram from "../assets/icons/social/instagram.svg";
import Facebook from "../assets/icons/social/facebook.svg";
import LinkedIn from "../assets/icons/social/linkedin.svg";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import anchorTo from "../helper/anchorTo";

const Wrapper = styled(Block)`
  border-top: 1px solid #e1e1e1;
  min-height: 0;
  background: #fff;
`;

const StyledContainer = styled(Container)`
  padding: 20px 0;
`;

const Logo = styled.img`
  max-height: 40px;
`;

const Row = styled.div`
  display: flex;
  max-width: 1280px;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 2vw;
  }

  & span,
  & a {
    text-align: center;
    color: #707070;
    font-size: 14px;
    text-decoration: none;
  }
`;

const Anchor = styled(AnchorLink)`
  text-decoration: none;
  color: #707070;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper as="footer">
      <StyledContainer>
        <Row>
          <span>
            <WeTechFoodLogo />
          </span>
          <span>
            <Anchor to={anchorTo("/#work", i18n)}>{t("menu.work")}</Anchor>
          </span>
          <span>
            <Anchor to={anchorTo("/#success", i18n)}>{t("menu.success")}</Anchor>
          </span>
          <span>
            <Anchor to={anchorTo("/#team", i18n)}>{t("menu.team")}</Anchor>
          </span>
          <span>
            <Anchor to={anchorTo("/detailedInfo/", i18n)}>{t("menu.info")}</Anchor>
          </span>
          <span>
            <a href="mailto:info@wetechfood.com">info@wetechfood.com</a>
          </span>
          <span>
            {new Date().getFullYear()} © {t("footer.all-rights-reserved")}
          </span>
        </Row>
      </StyledContainer>
    </Wrapper>
  );
};

export default Footer;
